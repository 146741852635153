<template>
    <div style="position:relative;width: 100%;overflow: hidden;min-height: 100vh">
        <div style="overflow: hidden;">
            <img class="bgc" src="../assets/login-bgc.png" alt="">
            <div class="code">
                <span>扫码下载APP</span>
                <img class="code-img" :src="preFix + DataList.download" alt="">
            </div>
        </div>
        <div class="main-business" id="mainBusiness">
            <h3>主营业务</h3>
            <p class="headline1">Main Business</p>
            <p class="headline2">实力承接短视频制作和分发短视频服务，万人矩阵带货</p>
            <div class="flow">
                <div class="flow-item" style="justify-content: center">
                    <div class="flow-item-tit">
                        <p>短视频</p>
                        <p>推广</p>
                    </div>
                    <ul>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/sjfb.png" alt="">
                                <p>商家上传视频</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/shtg.png" alt="">
                                <p>平台审核通过</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/drjd.png" alt="">
                                <p>达人接单</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/drff.png" alt="">
                                <p>达人分发视频</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="flow-item" style="justify-content: center">
                    <div class="flow-item-tit">
                        <p>短视频</p>
                        <p>制作</p>
                    </div>
                    <ul>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/sjfb.png" alt="">
                                <p>商家发布视频任务</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/pkjd.png" alt="">
                                <p>拍客接单</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/yyjyp.png" alt="">
                                <p>商家邮寄样品</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/wcsp.png" alt="">
                                <p>拍客完成视频</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="flow-item" style="margin-left: 75px">
                    <ul class="item-ul">
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/qrff.png" alt="">
                                <p>商家确认视频分发</p>
                            </div>
                        </li>
                        <li>
                            <div class="flow-item">
                                <img src="../assets/icon/drjd.png" alt="">
                                <p>达人接单</p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="flow-item">
                                <img src="../assets/icon/drff.png" alt="">
                                <p>达人分发视频</p>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="statistics">
            <div class="statistics-item">
                <img src="../assets/icon/jrjr.png" alt="">
                <div class="num">
                    <p>{{pcDataList.today_user}}<span>人</span></p>
                    <p>今日加入达人</p>
                </div>
            </div>
            <div class="statistics-item">
                <img src="../assets/icon/lmwz.png" alt="">
                <div class="num">
                    <p>{{pcDataList.proxy}}<span>个</span></p>
                    <p>联盟网站</p>
                </div>
            </div>
            <div class="statistics-item">
                <img src="../assets/icon/ptzsh.png" alt="">
                <div class="num">
                    <p>{{pcDataList.sj_user}} <span>人</span></p>
                    <p>平台总商户</p>
                </div>
            </div>
            <div class="statistics-item">
                <img src="../assets/icon/ptapk.png" alt="">
                <div class="num">
                    <p>{{pcDataList.pk_user}} <span>人</span></p>
                    <p>平台总拍客</p>
                </div>
            </div>
            <div class="statistics-item">
                <img src="../assets/icon/ptzdr.png" alt="">
                <div class="num">
                    <p>{{pcDataList.dr_user}} <span>人</span></p>
                    <p>平台总达人</p>
                </div>
            </div>
        </div>
        <div class="regard">
            <h3>关于我们</h3>
            <p>About Us</p>
            <span>
                平台整合各大电商平台店主，商家提供优质货源，专业的拍客提供视频持续输出
                <br>
                同时公司招募上万专业短视频达人参与视频分发到短视频平台，可直接帮商家减少验货商品成本和快递成本。目前旗下已聚集达人超10000位服
                <br>
                务于品牌吸商家超500家、并在不断提升中目前覆盖的短视频社交平合包含短视频，快手，小红书等
            </span>
            <div style="position:relative;height: 480px;margin-top: 100px">
                <img class="dian-img" src="../assets/diann.png" alt="">
                <img class="screen-img" src="../assets/screen.png" alt="">
            </div>

        </div>
        <div style="height: 128px;">
            <img class="footer-img" src="../assets/bot.png" alt="">
        </div>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "merchant",
        data(){
            return{
                pcDataList:null,
                DataList:null,
                preFix:null,
            }
        },
        created() {
          this.pcDataFn()
            this.preFix = api.url_prefix
        },
        methods:{
            pcDataFn(){
                this.$get({
                    url:api.pc_data
                }).then((res)=>{
                    this.pcDataList = res.data.data
                })
                this.$get({
                    url:api.contact
                }).then((res)=>{
                    this.DataList = res.data.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .bgc{
        min-width: 1200px;
        height: 802px;
        width: 100%;
    }
    .code{
        position: absolute;
        top: 150px;
        right: 250px;
        background-color: rgba(255,255,255,.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: 20px;
        padding: 20px 30px;
        .code-img{
            width: 150px;
            height: 150px;
            margin-top: 20px;
        }
    }
    .main-business {
        width: 100%;
        background-color: #F9FAFC;
        padding-top: 80px;
        padding-bottom: 150px;
    }
    .main-business>h3 {
        color: #000000;
        font-size: 36px;
        font-weight: 800;
        text-align: center;
    }
    .main-business>.headline1 {
        color: #000000;
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }
    .main-business>.headline2 {
        color: #999999;
        font-size: 16px;
        text-align: center;
        margin-bottom: 80px;
    }
    .main-business>.flow {
        width: 1260px;
        margin: 0 auto;
    }
    .main-business>.flow>.flow-item {
        display: flex;
         /*justify-content: center;*/
        align-items: center;
        img{
            width: 50px;
            height: 50px;
        }

    }
    .main-business>.flow>.flow-item .flow-item-tit {
        width: 140px;
        height: 140px;
        box-shadow: 0px 15px 22px 0px rgba(0, 0, 0,.1);
        border-radius: 16px;
        margin-right: 80px;
        margin-bottom: 60px;
        background-color: #fff;
        text-align: center;
        padding: 38px 0;
        box-sizing: border-box;
        color: #222222;
        font-size: 20px;
        position: relative;
    }
    .main-business>.flow>.flow-item .flow-item-tit::before {
        content: '';
        background-image: url(../assets/arr-right.png);
        background-size: cover;
        width: 42px;
        height: 14px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -60px;
    }
    .main-business>.flow>.flow-item .flow-item-tit p {
        line-height: 36px;
        margin: 0;
    }
    .main-business>.flow .flow-item ul {
        display: flex;
        padding: 0;
    }
    .main-business>.flow .flow-item ul li {
        width: 140px;
        height: 140px;
        text-align: center;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 15px 22px 0px rgba(0, 0 ,0 ,.1);
        box-sizing: border-box;
        padding-top: 32px;
        font-size: 14px;
        margin-right: 80px;
        margin-bottom: 60px;
        position: relative;
        &:nth-child(4):before{
            content: '';
            background: none;
        }
    }
    .item::before{
        content: '';
        background-image: none!important;
    }
    .main-business>.flow .flow-item ul li::before {
        content: '';
        background-image: url(../assets/arr-right.png);
        background-size: cover;
        width: 42px;
        height: 14px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -60px;
    }
    li {
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
    }
    .statistics {
        width: 100%;
        height: 360px;
        background-color: #08244C;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        padding-top: 126px;
        box-sizing: border-box;
        /*background-image: url(../img/tj-bgi.png);*/
        background-size: cover;
    }
    .statistics .statistics-item {
        display: flex;
        align-items: center;
        color: #fff;
        height: 72px;
        margin-right: 80px;
    }
    .statistics .statistics-item>img {
        width: 72px;
        height: 72px;
        margin-right: 16px;
    }
    statistics .statistics-item>.num p:first-child {
        font-size: 32px;
        line-height: 45px;
    }
    .statistics .statistics-item>.num p:last-child {
        opacity: 0.6;
        font-size: 20px;
    }
    .num{
        font-size: 24px;
        p{
            margin:5px 0;
        }
    }
    .regard{
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        min-height: 200px;
        h3{
            font-size: 36px;
            margin-bottom: 10px;
        }
        p{
            font-size: 24px;
            margin-top: 0;
        }
        span{
            line-height: 30px;
            color: #999999;
            display: block;
        }
        .dian-img{
            position: absolute;
            width: 770px;
            left: 50%;
            transform: translateX(-50%);
        }
        .screen-img{
            position: absolute;
            width: 586px;
            height: 368px;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .footer-img{
        width: 100%;
        height: 100%;
    }
</style>
